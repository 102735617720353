.container1 {
  background-image: linear-gradient(to right, #428dff, #aa56ff, #ff47aa);
  padding: 2px;
  border-radius: 10px;
}
.container {
  position: relative;
  display: flex;
  width: fit-content;
  border-radius: 10px;
  padding: 5px;
  width: 100%;
  align-items: center;
  background-color: #ffffff;

  .step0 {
    display: flex;
    align-items: center;
    // width: 100%;
    flex: 1;
    .img {
      height: 100%;
      width: 70px;
      cursor: pointer;
      vertical-align: middle;
    }
    .addAccount {
      font-size: 15px;
      color: #99a1b0;
      margin-left: 10px;
    }
  }
  > .step2 {
    display: flex;
    align-items: center;
    flex: 1;
    .input {
      border: none;
      border-radius: 1rem;
      background: none;
      padding: 0.5rem;
      font-size: 1rem;
      color: #02183a;
      transition: border 150ms cubic-bezier(0.4, 0, 0.2, 1);
      flex: 1;
      padding-top: 0.7rem;
      &:focus {
        outline: none;
        border: none;
      }
      &:valid {
        outline: none;
        border: none;
      }
    }
    .img1 {
      height: 20px;
      width: 20px;
      cursor: pointer;
      margin-right: 10px;
      vertical-align: middle;
      margin-left: 16px;
    }
  }
  // .img1 {
  //   height: 20px;
  //   width: 20px;
  //   margin-left: 10px;
  //   border-radius: 50%;
  // }
  .platforms {
    position: absolute;
    bottom: -320px;
    left: 0px;
    background-color: red;
    width: 100%;
  }

  .label {
    position: absolute;
    left: 75px;
    color: #02183a;
    pointer-events: none;
    transform: translateY(0.4rem);
    transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
    top: 5px;
    opacity: 0;
  }
  .error {
    position: absolute;
    right: 7rem;
    top: 15px;
    color: #ffffff;
    background-color: #081a3a;
    display: inline-block;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 3px;
    cursor: pointer;
    padding-left: 1px;
  }

  .label2 {
    transform: translateY(-70%) translateX(-70px) scale(0.8);
    background-color: #ffffff;
    padding: 0 0.2em;
    color: #2196f3;
    opacity: 1;
  }
  .btn {
    button {
      border: none;
      height: 2.5rem;
      box-shadow: none;
      background: linear-gradient(270deg, #ff47aa 0%, #aa56ff 30%, #418dff 80%), #02183a;
      color: #ffffff;
      border-radius: 10px;
      width: 6rem;
      &:hover {
        color: #ffffff !important;
      }
    }
  }
}

.platforms {
  background-color: #ffffff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  padding: 20px;
  border-radius: 10px;
  > div:nth-of-type(1) {
    margin-bottom: 10px;
  }
  > div:nth-of-type(2) {
    margin-bottom: 10px;
    margin-top: 10px;
  }
  .ul1 {
    li {
      display: flex;
      align-items: center;
      width: 200px;
      cursor: pointer;
      padding: 5px;
      border-radius: 20px;
      &:hover {
        background-color: #f1f2f3;
      }
      img {
        width: 20px;
        height: 20px;
        margin-right: 5px;
        border-radius: 50%;
      }
    }
  }
  .ul {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(4, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 5px;
    li {
      display: flex;
      align-items: center;
      width: 200px;
      cursor: pointer;
      padding: 5px;
      border-radius: 20px;
      &:hover {
        background-color: #f1f2f3;
      }
      img {
        width: 20px;
        height: 20px;
        margin-right: 10px;
        border-radius: 50%;
      }
    }
  }
}
.vaildMessageContainer {
  height: 20px;
  .vaildMessage {
    text-align: left !important;
    font-size: 14px;
    margin-top: 5px;
  }
}

@media (max-width: 1024px) and (min-width: 900px) {
  .container {
    min-width: 400px;
  }
}

@media (max-width: 899px) and (min-width: 576px) {
  .container {
    margin: auto;
  }
  .platforms {
    .ul {
      li {
        width: 140px;
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .img1 {
    margin-left: 2px !important;
  }
  // .error {
  //   right: 5rem;
  // }
  .platforms {
    padding: 25px;
    .ul {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(6, 1fr);
      li {
        width: 130px;
      }
    }
  }

  .container {
    background-color: #ffffff;
    margin: auto;
    .step0 {
      height: 35px;
      text-align: left;
    }
  }
}

